import React from 'react';
import './css/error.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Logo from '../images/logo_valleybeach_black.png';
import { useTranslation } from 'react-i18next';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#000' },
    // secondary: 'green',
    // error: 'red',
  },
});

const Error404 = () => {
  const { t } = useTranslation();
  return (
    <MuiThemeProvider theme={theme}>
      <div className='home'>
        <div className='error-left'>
          <img src={Logo} alt='' className='logo' />
          <main>
            <div className='titulo'>
              <h2>Whooops...</h2>
            </div>
            <div>
              <p>
                {/* {t('Este link já foi anteriormente utilizado, por favor verifique
                novamente o seu email.')} */}
                {t(
                  'Este link já foi anteriormente utilizado, por favor verifique novamente o seu email.'
                )}
              </p>
              <p>
                {t(
                  'Em caso de dúvida, contacte os serviços hoteleiros para gerar um novo pedido de pequeno almoço.'
                )}
              </p>
            </div>
            <a href='https://valleybeach.pt/'>
              <Button variant='contained' color='primary' className='btn'>
                {t('Ir para Valley Beach')}
              </Button>
            </a>
          </main>
          <div className='bottom'>
            {/* <p>* Para mais informações, contacte o seu anfitrião.</p> */}
          </div>
        </div>
        <div className='error-right'>
          {/* <div className='center404'></div> */}
          <div className='center404Letters'>
            <span className='span404'>404</span>
            <span className='spanPage'>{t('Página não encontrada')}</span>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default Error404;
