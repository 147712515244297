/* eslint-disable eqeqeq */
import React from 'react';
import '../css/confirmado.css';

const Resumo = ({ user, loading, pedido, pedidoCriancas }) => {
  if (loading == true) {
    return '';
  } else {
    return (
      <>
        <h4 style={{ marginBottom: 0 }}>{user.info.menu_descricao}</h4>
        {pedido &&
          pedido.map((produtos, index) => (
            <div key={index}>
              {produtos.listaprodutos.length > 0 ? (
                <div className='resumo-list'>
                  {produtos.listaprodutos.map((produto) => (
                    <div className='resumo-item' key={produto.id}>
                      <div>{produto.descricao}</div>
                      <div className='quantidadeConfirmada'>
                        x {produto.quantidade}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        {pedidoCriancas && pedidoCriancas.length > 0 ? (
          <h4 style={{ marginBottom: 0 }}>Criança</h4>
        ) : null}

        {pedidoCriancas &&
          pedidoCriancas.map((produtos, index) => (
            <div key={index}>
              {produtos.listaprodutos.length > 0 ? (
                <div className='resumo-list'>
                  {produtos.listaprodutos.map((produto) => (
                    <div className='resumo-item' key={produto.id}>
                      <div>{produto.descricao}</div>
                      <div className='quantidadeConfirmada'>
                        x {produto.quantidade}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
      </>
    );
  }
};

export default Resumo;
