import React from 'react';
import { Button } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useLocation, useHistory } from 'react-router-dom';
import Instance from '../axiosRedirect';
import { useTranslation } from 'react-i18next';

import Resumo from './components/resumo';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#000' },
    // secondary: 'green',
    // error: 'red',
  },
});

const Confirmar = ({
  user,
  setUser,
  loading,
  pedido,
  pedidoCriancas,
  setNome,
}) => {
  //   console.log(pedido && pedido);

  const userToken = useLocation().pathname.split('/')[1];
  const userManualToken = useLocation().pathname.split('/')[2];
  const secret = localStorage.secret ? localStorage.secret : null;

  const history = useHistory();
  const handleBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  // console.log(user.info);

  const submit = (e) => {
    e.preventDefault();
    console.log(pedido, pedidoCriancas);

    // Adultos
    let listprodutos = pedido.map((produtos) => {
      return produtos.listaprodutos;
    });
    listprodutos = listprodutos.flat();
    listprodutos = Object.assign([
      ...listprodutos.map(({ id, quantidade }) => ({
        produtosId: id,
        qtd: quantidade,
      })),
    ]);
    // Crianças
    let listprodutosCriancas = pedidoCriancas.map((produtos) => {
      return produtos.listaprodutos;
    });
    listprodutosCriancas = listprodutosCriancas.flat();
    listprodutosCriancas = Object.assign([
      ...listprodutosCriancas.map(({ id, quantidade }) => ({
        produtosId: id,
        qtd: quantidade,
      })),
    ]);

    setNome(user.info.clienteNome);
    setUser({
      info: {},
      menu: [],
    });

    if (userToken !== 'manual') {
      const yData = [
        {
          token: userToken,
          listprodutos,
          listprodutosCriancas,
        },
      ];

      console.log(yData);

      Instance()
        .post('/frontend/encomenda', { yData })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    } else {
      const yData = [
        {
          token: userManualToken,
          secret: secret,
          listprodutos,
          listprodutosCriancas,
        },
      ];

      Instance()
        .post('/frontend/encomenda/manual', { yData })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    }

    history.push(`/${userToken}/pedidoEnviado`);
  };

  const { t } = useTranslation();

  return (
    <MuiThemeProvider theme={theme}>
      <div className='home'>
        <div className='menu-left'>
          <div>
            <div style={{ display: 'flex' }}>
              <Button
                variant='outlined'
                className='back-btn'
                onClick={handleBack}
              >
                <ChevronLeftIcon />
              </Button>
              <div
                style={{
                  marginLeft: '20px',
                  marginRight: '20px',
                  // borderBottom: '1px solid #ccc',
                  width: '80%',
                  height: '70px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <h3
                  style={{
                    marginTop: '40px',
                    // marginLeft: '30px',
                  }}
                >
                  {t('Confirmar Pedido')}
                </h3>
              </div>
            </div>
          </div>
          <main>
            <Resumo
              user={user}
              loading={loading}
              pedido={pedido}
              pedidoCriancas={pedidoCriancas}
            />
            <div className='btn-right'>
              {/* <Link to={`/${userToken}/pedidoEnviado`}> */}
              <Button
                variant='contained'
                color='primary'
                className='btn'
                onClick={submit}
              >
                {t('Concluir')}
              </Button>
              {/* </Link> */}
            </div>
            <br />
          </main>
          <div className='bottom'></div>
        </div>
        <div className='menu-right'></div>
      </div>
    </MuiThemeProvider>
  );
};

export default Confirmar;
