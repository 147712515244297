import axios from 'axios';

// import { createBrowserHistory } from 'history'; // or createBrowserHistory
import BASE_URL from './BASE_URL';

// const history = createBrowserHistory({
//   basename: '/',
//   forceRefresh: true,
// });

const signal = axios.CancelToken.source();

const Instance = () => {
  const instance = axios.create({
    baseURL: `${BASE_URL}`,
    cancelToken: signal.token,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log(error.response);
      switch (error.response.status) {
        case 404:
          console.log('passou aqui 404');
          //   history.push('/404');

          break;

        default:
          console.log('From Interceptor');
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default Instance;
