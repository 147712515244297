import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';

//i18n
import './Translations/index';

// Retira todos os console.log do projeto
// eslint-disable-next-line func-names
if (process.env.NODE_ENV === 'production' && 'serviceWorker')
  console.log = function () {};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
