import React, { useEffect } from 'react';
import './css/home.css';
import Logo from '../images/logo_valleybeach_black.png';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import Instance from '../axiosRedirect';
import { useTranslation, Trans } from 'react-i18next';

// Components

import Spinner from '../Global/Spinner/Spinner';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#000' },
    // secondary: 'green',
    // error: 'red',
  },
});

const ManualHome = ({ user = {}, setUser }) => {
  const userManualToken = useLocation().pathname.split('/')[2];
  const userManualSecretToken = useLocation().pathname.split('/')[3];
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const fetchUser = () => {
    Instance()
      .get(
        `/frontend/encomenda/manual/${userManualToken}/${userManualSecretToken}`
      )
      .then((data) => {
        // localStorage.setItem('Language', data.data.yData.linguaCode);
        i18n.changeLanguage(data.data.yData.linguaCode);
        document.title = t('Valley Beach - Pequenos Almoços');
        setUser({
          ...user,
          info: data.data.yData,
        });
      })
      .then(() => localStorage.setItem('secret', userManualSecretToken))
      .then(() => history.push(`/manual/${userManualToken}`))
      .catch((err) => {
        // console.log(err);
        history.push('/404');
      });
  };

  useEffect(() => {
    if (JSON.stringify(user.info) === JSON.stringify({})) {
      fetchUser();
    }
    // localStorage.removeItem('secret');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      {user ? (
        <div className='home'>
          <div className='home-left'>
            <img src={Logo} alt='' className='logo' />
            <main>
              <div className='titulo'>
                <h2 style={{ marginTop: '100px' }}>
                  <Spinner />
                </h2>
              </div>
            </main>
            <div className='bottom'>
              <p>
                <Trans>
                  * O seu pequeno almoço será entregue até às
                  <strong> 8h30</strong> à porta do seu quarto.
                </Trans>
                <br />
              </p>
            </div>
          </div>
          <div className='home-right'></div>
        </div>
      ) : (
        fetchUser()
      )}
    </MuiThemeProvider>
  );
};

export default ManualHome;
