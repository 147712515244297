import React from 'react';
// import './css/home.css';
import Logo from '../images/logo_valleybeach_black.png';
import { Button } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#000' },
    // secondary: 'green',
    // error: 'red',
  },
});

const PedidoEnviado = ({ user, setUser, nome }) => {
  const { t } = useTranslation();

  return (
    <MuiThemeProvider theme={theme}>
      <div className='home'>
        <div className='home-left'>
          <img src={Logo} alt='' className='logo' />
          <main>
            <div className='titulo'>
              <h2>{t('Pedido enviado!')}</h2>
            </div>
            <div>
              <p>
                <Trans i18nKey='Sent'>
                  <strong>{{ nome }}</strong>, obrigado por escolher os nossos
                  serviços! Esperamos que aproveite a sua estadia, estamos ao
                  seu dispor*.
                </Trans>
              </p>
            </div>
            <a href='https://valleybeach.pt/'>
              <Button variant='contained' color='primary' className='btn'>
                {t('Ir para Valley Beach')}
              </Button>
            </a>
          </main>
          <div className='bottom'>
            <p>{t('* Para mais informações, contacte o seu anfitrião.')}</p>
          </div>
        </div>
        <div className='home-right'></div>
      </div>
    </MuiThemeProvider>
  );
};

export default PedidoEnviado;
