import './spinner.css';
const Spinner = () => {
  return (
    <div className='sk-display'>
      <div
        className='sk-chase'
        style={{
          margin: 'auto',
        }}
      >
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '40px',
          fontSize: '16px',
        }}
      >
        A carregar dados ...
      </div>
    </div>
  );
};

export default Spinner;
