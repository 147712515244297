import React, { useEffect, Fragment } from 'react';
import './css/menu.css';

import { Button } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Instance from '../axiosRedirect';
import { useTranslation } from 'react-i18next';

import Categorias from './components/categorias';
import CategoriasCrianca from './components/categoriasCrianca';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#000' },
    // secondary: 'green',
    // error: 'red',
  },
});

const Menu = ({
  user,
  setUser,
  loading,
  setLoading,
  pedido,
  categoriasAdulto,
  setCategoriasAdulto,
  //Crianca
  pedidoCriancas,
  setPedidoCriancas,
  categoriasCrianca,
  setCategoriasCrianca,
}) => {
  const userToken = useLocation().pathname.split('/')[1];
  const userManualToken = useLocation().pathname.split('/')[2];
  const h = useHistory();
  console.log(userManualToken);
  const fetchMenu = async () => {
    if (userToken === 'manual') {
      await Instance()
        .get(`/frontend/produto/${userManualToken}`)
        .then((data) => {
          // console.log('data menu', data.data);
          setUser({
            ...user,
            menu: data.data.yData,
          });
          setCategoriasAdulto(data.data.initialStateAdultos);
          setCategoriasCrianca(data.data.initialStateCriancas);
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          h.push('/404');
        });
    } else {
      await Instance()
        .get(`/frontend/produto/${userToken}`)
        .then((data) => {
          // console.log('data menu', data.data);
          setUser({
            ...user,
            menu: data.data.yData,
          });
          setCategoriasAdulto(data.data.initialStateAdultos);
          setCategoriasCrianca(data.data.initialStateCriancas);
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          h.push('/404');
        });
    }
  };

  const checkSelectedProduct = () => {
    let selected = false;

    pedido &&
      pedido.map((p) => {
        if (p.listaprodutos.length > 0) {
          selected = true;
        }
        return null;
      });

    return selected;
  };
  const checkSelectedProductCrianca = () => {
    let selected = false;

    pedidoCriancas &&
      pedidoCriancas.map((p) => {
        if (p.listaprodutos.length > 0) {
          selected = true;
        }
        return null;
      });

    return selected;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (JSON.stringify(user.menu) === JSON.stringify([])) {
      fetchMenu();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  return (
    <MuiThemeProvider theme={theme}>
      {user ? (
        <div className='home'>
          <div className='menu-left'>
            {/* <img src={Logo} alt='' className='logo' /> */}

            <div className='imagem-menu'>
              {userToken === 'manual' ? (
                <Link to={`/manual/${userManualToken}`} className='link-btn'>
                  <Button variant='outlined' className='back-btn'>
                    <ChevronLeftIcon />
                  </Button>
                </Link>
              ) : (
                <Link to={`/${userToken}`} className='link-btn'>
                  <Button variant='outlined' className='back-btn'>
                    <ChevronLeftIcon />
                  </Button>
                </Link>
              )}

              <div className='menu-image'>
                {/* <img src={Menu1} alt='' /> */}
              </div>
            </div>
            <main>
              <div className='tipo-menu'>
                <h2>{user.info.menu_descricao}</h2>
              </div>

              {user &&
                user.menu &&
                user.menu.categoriasAdultos &&
                user.menu.categoriasAdultos.map((categoriaMenu) => (
                  <Fragment key={categoriaMenu.id}>
                    <Categorias
                      user={user}
                      setUser={setUser}
                      loading={loading}
                      setLoading={setLoading}
                      categoriaMenu={categoriaMenu}
                      categoriasAdulto={categoriasAdulto}
                      setCategoriasAdulto={setCategoriasAdulto}
                    />
                  </Fragment>
                ))}
              {user &&
              user.menu &&
              user.menu.categoriasCriancas &&
              user.menu.categoriasCriancas.length !== undefined ? (
                <div className='tipo-menu'>
                  <h2>Criança</h2>
                </div>
              ) : null}

              {user &&
                user.menu &&
                user.menu.categoriasCriancas &&
                user.menu.categoriasCriancas.map((categoriaMenu) => (
                  <Fragment key={categoriaMenu.id}>
                    <CategoriasCrianca
                      user={user}
                      setUser={setUser}
                      loading={loading}
                      setLoading={setLoading}
                      categoriaMenu={categoriaMenu}
                      //Crianca
                      categoriasCrianca={categoriasCrianca}
                      setCategoriasCrianca={setCategoriasCrianca}
                    />
                  </Fragment>
                ))}

              {userToken === 'manual' ? (
                <div className='btn-right'>
                  {JSON.stringify(user.info) === JSON.stringify({}) ? (
                    <Link to={`manual/${userToken}`}>
                      <Button
                        variant='contained'
                        color='primary'
                        className='btn'
                      >
                        {t('Confirmar')}
                      </Button>
                    </Link>
                  ) : (
                    <>
                      {checkSelectedProduct() ||
                      checkSelectedProductCrianca() ? (
                        <Link to={`/manual/${userManualToken}/confirmar`}>
                          <Button
                            variant='contained'
                            color='primary'
                            className='btn'
                          >
                            {t('Confirmar pedido')}
                          </Button>
                        </Link>
                      ) : (
                        <Button
                          variant='contained'
                          color='primary'
                          className='btn'
                          disabled
                        >
                          {t('Confirmar pedido')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className='btn-right'>
                  {JSON.stringify(user.info) === JSON.stringify({}) ? (
                    <Link to={`/${userToken}`}>
                      <Button
                        variant='contained'
                        color='primary'
                        className='btn'
                      >
                        {t('Confirmar')}
                      </Button>
                    </Link>
                  ) : (
                    <>
                      {checkSelectedProduct() ||
                      checkSelectedProductCrianca() ? (
                        <Link to={`/${userToken}/confirmar`}>
                          <Button
                            variant='contained'
                            color='primary'
                            className='btn'
                          >
                            {t('Confirmar pedido')}
                          </Button>
                        </Link>
                      ) : (
                        <Button
                          variant='contained'
                          color='primary'
                          className='btn'
                          disabled
                        >
                          {t('Confirmar pedido')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              )}

              <br />
            </main>
            <div className='bottom'>
              {/* <p>
                * Para mudar o seu <b> tipo de menu</b>, contacte o anfitrião.
                <br />
                ** O seu pequeno almoço será entregue até ás <b> 8h </b>à porta
                do seu quarto.
              </p> */}
            </div>
          </div>
          <div className='menu-right'></div>
        </div>
      ) : (
        fetchMenu()
      )}
    </MuiThemeProvider>
  );
};

export default Menu;
