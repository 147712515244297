import React, { useEffect } from 'react';
import './css/home.css';
import Logo from '../images/logo_valleybeach_black.png';
import { Button } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Instance from '../axiosRedirect';
import { useTranslation, Trans } from 'react-i18next';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#000' },
    // secondary: 'green',
    // error: 'red',
  },
});

const Home = ({ user, setUser }) => {
  const userToken = useLocation().pathname.split('/')[1];
  const userManualToken = useLocation().pathname.split('/')[2];
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const fetchUser = () => {
    if (userToken === 'manual') {
      Instance()
        .get(
          `/frontend/encomenda/manual/${userManualToken}/${localStorage.secret}`
        )
        .then((data) => {
          // localStorage.setItem('Language', data.data.yData.linguaCode);
          i18n.changeLanguage(data.data.yData.linguaCode);
          document.title = t('Valley Beach - Pequenos Almoços');
          setUser({
            ...user,
            info: data.data.yData,
          });
        })
        .catch((err) => {
          // console.log(err);
          history.push('/404');
        });
    } else {
      Instance()
        .get(`/frontend/encomenda/${userToken}`)
        .then((data) => {
          console.log(data);
          // localStorage.setItem('Language', data.data.yData.linguaCode);
          i18n.changeLanguage(data.data.yData.linguaCode);
          document.title = t('Valley Beach - Pequenos Almoços');
          setUser({
            ...user,
            info: data.data.yData,
          });
        })
        .catch((err) => {
          // console.log(err);
          history.push('/404');
        });
    }
  };

  useEffect(() => {
    if (JSON.stringify(user.info) === JSON.stringify({})) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clienteNome = user && user.info && user.info.clienteNome;
  const bookingID = user && user.info && user.info.reservaBookingID;
  const menuDescricao = user && user.info && user.info.menu_descricao;
  return (
    <MuiThemeProvider theme={theme}>
      {user ? (
        <div className='home'>
          <div className='home-left'>
            <img src={Logo} alt='' className='logo' />
            <main>
              <div className='titulo'>
                <h2>{t('Bem Vindo!')}</h2>
              </div>
              <div>
                <p>
                  <Trans
                    i18nKey='Hello'
                    clienteNome={clienteNome}
                    bookingID={bookingID}
                    menuDescricao={menuDescricao}
                    // components={{ bold: <b /> }}
                  >
                    Olá <strong>{{ clienteNome }}</strong>, a sua reserva
                    <strong> {{ bookingID }}</strong>, tem associado o menu
                    {{ menuDescricao }}* , definido aquando o seu check-in.
                  </Trans>
                </p>

                <p>
                  {t(
                    'Por favor, preencha o formulário, para o fornecedor* obter o seu pedido.'
                  )}
                </p>
              </div>

              {userToken === 'manual' ? (
                <Link
                  to={`/manual/${userManualToken}/menu/${user.info.menus_id}`}
                >
                  <Button variant='contained' color='primary' className='btn'>
                    {t('Começar')}
                  </Button>
                </Link>
              ) : (
                <Link to={`/${userToken}/menu/${user.info.menus_id}`}>
                  <Button variant='contained' color='primary' className='btn'>
                    {t('Começar')}
                  </Button>
                </Link>
              )}
            </main>
            <div className='bottom'>
              <p>
                <Trans>
                  * O seu pequeno almoço será entregue até às
                  <strong> 8h30</strong> à porta do seu quarto.
                </Trans>
                <br />
              </p>
            </div>
          </div>
          <div className='home-right'></div>
        </div>
      ) : (
        fetchUser()
      )}
    </MuiThemeProvider>
  );
};

export default Home;
