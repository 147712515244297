import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  useLocation,
} from 'react-router-dom';

import Home from './Home';
import ManualHome from './Home/ManualHome';
import Error404 from './Error';
import Menu from './Menu';
import Confirmar from './Confirmar';
import PedidoEnviado from './PedidoEnviado';
import { Suspense } from 'react';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({
    info: {},
    menu: [],
  });
  // const [pao, setPao] = useState({});
  // const [doces, setDoces] = useState({});
  // const [manteiga, setManteiga] = useState({});
  // const [sumo, setSumo] = useState({});
  // const [cafe, setCafe] = useState({});
  // const [extra, setExtra] = useState({});
  const [categoriasAdulto, setCategoriasAdulto] = useState();
  const [categoriasCrianca, setCategoriasCrianca] = useState();

  const [pedido, setPedido] = useState();
  const [pedidoCriancas, setPedidoCriancas] = useState();

  // console.log('pedido', pedido);
  // console.log('pedidoCriancas', pedidoCriancas);

  useEffect(() => {
    // let auxPao = { ...pao };
    // delete auxPao.qtdTotal;
    // auxPao = Object.values(auxPao);
    // auxPao = auxPao.filter((p) => p.quantidade > 0);

    // let auxDoces = { ...doces };
    // delete auxDoces.qtdTotal;
    // auxDoces = Object.values(auxDoces);
    // auxDoces = auxDoces.filter((d) => d.quantidade > 0);

    // let auxManteiga = { ...manteiga };
    // delete auxManteiga.qtdTotal;
    // auxManteiga = Object.values(auxManteiga);
    // auxManteiga = auxManteiga.filter((m) => m.quantidade > 0);

    // let auxSumo = { ...sumo };
    // delete auxSumo.qtdTotal;
    // auxSumo = Object.values(auxSumo);
    // auxSumo = auxSumo.filter((s) => s.quantidade > 0);

    // let auxCafe = { ...cafe };
    // delete auxCafe.qtdTotal;
    // auxCafe = Object.values(auxCafe);
    // auxCafe = auxCafe.filter((c) => c.quantidade > 0);

    // let auxExtra = { ...extra };
    // delete auxExtra.qtdTotal;
    // auxExtra = Object.values(auxExtra);
    // auxExtra = auxExtra.filter((e) => e.quantidade > 0);

    let aux = [];

    if (categoriasAdulto) {
      aux = Object.entries(categoriasAdulto).map((cat) => {
        // const mainCat = cat[0];
        let listaprodutos = { ...cat[1] };

        delete listaprodutos.qtdTotal;
        listaprodutos = Object.values(listaprodutos);
        listaprodutos = listaprodutos.filter((e) => e.quantidade > 0);

        return {
          // slug: mainCat,
          listaprodutos,
        };
      });
    }

    setPedido(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriasAdulto]);
  useEffect(() => {
    let auxCriancas = [];

    if (categoriasCrianca) {
      auxCriancas = Object.entries(categoriasCrianca).map((cat) => {
        // const mainCat = cat[0];
        let listaprodutos = { ...cat[1] };

        delete listaprodutos.qtdTotal;
        listaprodutos = Object.values(listaprodutos);
        listaprodutos = listaprodutos.filter((e) => e.quantidade > 0);

        return {
          // slug: mainCat,
          listaprodutos,
        };
      });
    }

    setPedidoCriancas(auxCriancas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriasCrianca]);

  const userToken = useLocation().pathname.split('/')[1];
  const userManualToken = useLocation().pathname.split('/')[2];

  const [nome, setNome] = useState();

  return (
    <Suspense fallback='...is loading'>
      <div className='App'>
        <Switch>
          <Route exact path='/404' component={Error404} />

          {/* Cliente */}
          <Route exact path='/:token'>
            <Home user={user} setUser={setUser} />
          </Route>

          <Route exact path='/:token/menu/:menuId'>
            {JSON.stringify(user.info) === JSON.stringify({}) ? (
              <Redirect to={`/${userToken}`} />
            ) : (
              <Menu
                user={user}
                setUser={setUser}
                loading={loading}
                setLoading={setLoading}
                //Adultos
                pedido={pedido}
                setPedido={setPedido}
                categoriasAdulto={categoriasAdulto}
                setCategoriasAdulto={setCategoriasAdulto}
                //Criancas
                pedidoCriancas={pedidoCriancas}
                setPedidoCriancas={setPedidoCriancas}
                categoriasCrianca={categoriasCrianca}
                setCategoriasCrianca={setCategoriasCrianca}
              />
            )}
          </Route>
          <Route exact path='/:token/confirmar'>
            {JSON.stringify(user.info) === JSON.stringify({}) ? (
              <Redirect to={`/${userToken}`} />
            ) : (
              <Confirmar
                user={user}
                setUser={setUser}
                loading={loading}
                pedido={pedido}
                pedidoCriancas={pedidoCriancas}
                setNome={setNome}
              />
            )}
          </Route>
          <Route exact path='/:token/pedidoEnviado'>
            {JSON.stringify(nome) === JSON.stringify() ? (
              <Redirect to={`/${userToken}`} />
            ) : (
              <PedidoEnviado
                user={user}
                setUser={setUser}
                nome={nome}
                setNome={setNome}
              />
            )}
          </Route>
          {/* Administração */}
          <Route exact path='/manual/:token'>
            <Home user={user} setUser={setUser} />
          </Route>

          <Route exact path='/manual/:token/menu/:menuId'>
            {JSON.stringify(user.info) === JSON.stringify({}) ? (
              <Redirect to={`/manual/${userManualToken}`} />
            ) : (
              <Menu
                user={user}
                setUser={setUser}
                loading={loading}
                setLoading={setLoading}
                pedido={pedido}
                setPedido={setPedido}
                categoriasAdulto={categoriasAdulto}
                setCategoriasAdulto={setCategoriasAdulto}
                //Crianca
                categoriasCrianca={categoriasCrianca}
                setCategoriasCrianca={setCategoriasCrianca}
              />
            )}
          </Route>
          <Route exact path='/manual/:token/confirmar'>
            {JSON.stringify(user.info) === JSON.stringify({}) ? (
              <>
                <Redirect to={`/manual/${userManualToken}`} />
              </>
            ) : (
              <>
                {/* {console.log('Passei na route para o commponent')} */}
                <Confirmar
                  user={user}
                  setUser={setUser}
                  loading={loading}
                  pedido={pedido}
                  pedidoCriancas={pedidoCriancas}
                  setNome={setNome}
                />
              </>
            )}
          </Route>
          <Route exact path='/manual/:token/:secret'>
            <ManualHome user={user} setUser={setUser} />
          </Route>
          <Route path='*'>
            <Redirect to='/404' />
          </Route>
        </Switch>
      </div>
    </Suspense>
  );
};

export default withRouter(App);
