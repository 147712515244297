/* eslint-disable eqeqeq */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';

const CategoriasCrianca = ({
  user,
  setUser,
  loading,
  setLoading,
  categoriaMenu,
  //Crianca
  categoriasCrianca,
  setCategoriasCrianca,
}) => {
  const handleAddCategoria = (e, produtos) => {
    setCategoriasCrianca({
      ...categoriasCrianca,
      [categoriaMenu.slug]: {
        ...categoriasCrianca[categoriaMenu.slug],
        [produtos]: {
          ...categoriasCrianca[categoriaMenu.slug][produtos],

          quantidade:
            categoriasCrianca[categoriaMenu.slug][produtos].quantidade + 1,
        },
        qtdTotal: categoriasCrianca[categoriaMenu.slug].qtdTotal + 1,
      },
    });
  };
  const handleRemoveCategoria = (e, produtos) => {
    setCategoriasCrianca({
      ...categoriasCrianca,
      [categoriaMenu.slug]: {
        ...categoriasCrianca[categoriaMenu.slug],
        [produtos]: {
          ...categoriasCrianca[categoriaMenu.slug][produtos],

          quantidade:
            categoriasCrianca[categoriaMenu.slug][produtos].quantidade - 1,
        },
        qtdTotal: categoriasCrianca[categoriaMenu.slug].qtdTotal - 1,
      },
    });
  };

  const { t } = useTranslation();

  //podemos aqui retornar uma mensagem de espera
  if (loading == true) {
    return null;
  } else {
    return (
      <div>
        <>
          <span className='categoria-titulo'>
            <h2>{categoriaMenu.descricao}</h2>
            <p>
              {t('Escolher até')}{' '}
              {categoriaMenu.quantidade -
                categoriasCrianca[categoriaMenu.slug].qtdTotal}
            </p>
          </span>
          {categoriaMenu &&
            categoriaMenu.listaprodutos.map((produtos) => (
              <div className='item-list' key={produtos.id}>
                <div className='item-box'>
                  <div className='quantidade'>
                    {categoriasCrianca[categoriaMenu.slug][produtos.id]
                      .quantidade == 0 ? (
                      <>
                        {/* {console.log('categorias', categorias)}
                        {console.log('categoriaMenu', categoriaMenu)}
                        {console.log('produtos', produtos)} */}

                        <IconButton
                          aria-label='more'
                          name={produtos.id}
                          //   data-categoria='pao'
                          onClick={(e, value) =>
                            handleAddCategoria(e, produtos.id)
                          }
                          disabled={
                            categoriaMenu.quantidade -
                              categoriasCrianca[categoriaMenu.slug].qtdTotal ==
                            0
                          }
                        >
                          <AddCircleOutlineIcon fontSize='small' />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          aria-label='less'
                          name='quantidade'
                          //   data-categoria='pao'
                          onClick={(e, value) =>
                            handleRemoveCategoria(e, produtos.id)
                          }
                        >
                          <RemoveCircleOutlineIcon fontSize='small' />
                        </IconButton>
                        {
                          categoriasCrianca[categoriaMenu.slug][produtos.id]
                            .quantidade
                        }
                        <IconButton
                          aria-label='more'
                          name='quantidade'
                          //   data-categoria='pao'
                          onClick={(e, value) =>
                            handleAddCategoria(e, produtos.id)
                          }
                          disabled={
                            categoriaMenu.quantidade -
                              categoriasCrianca[categoriaMenu.slug].qtdTotal ==
                              0 ||
                            categoriasCrianca[categoriaMenu.slug][produtos.id]
                              .quantidade == produtos.quantidade
                          }
                        >
                          <AddCircleOutlineIcon fontSize='small' />
                        </IconButton>
                      </>
                    )}
                  </div>
                  <div className='nome'>{produtos.descricao}</div>
                </div>
              </div>
            ))}
        </>
      </div>
    );
  }
};

export default CategoriasCrianca;
